import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import React from "react";
import { Loader } from "react-feather";
import {
  type UserModel,
  useAttendanceMetricsQuery,
  useAttendanceRatioQuery,
  useTotalHostedParticipantsQuery,
  useTotalPublishedArticlesQuery,
} from "src/types/graphql";

/**
 * Helpers
 */
const getHoursAndMinutes = (minutes?: number | null): string => {
  return minutes ? `${Math.floor(minutes / 60)}h ${minutes % 60}m` : "0h 0m";
};

/**
 * Types
 */
interface Props {
  user: UserModel;
}

const UserPageDetailsMetricsCard: React.FC<Props> = ({ user }: Props) => {
  const userId = user.id;
  const { data, loading } = useTotalPublishedArticlesQuery({
    variables: { userId },
  });

  const { data: attendanceRatioData, loading: attendanceRatioLoading } = useAttendanceRatioQuery({
    variables: { userId },
  });

  const totalArticles = data?.totalPublishedArticles ?? 0;

  const { data: totalHostedParticipantsData, loading: totalHostedParticipantsLoading } =
    useTotalHostedParticipantsQuery({
      fetchPolicy: "cache-and-network",
      variables: { userId },
    });

  const { data: attendanceMetricsData, loading: attendanceMetricsLoading } =
    useAttendanceMetricsQuery({
      fetchPolicy: "cache-and-network",
      variables: { userId },
    });

  if (
    loading ||
    attendanceRatioLoading ||
    totalHostedParticipantsLoading ||
    attendanceMetricsLoading
  ) {
    return <Loader />;
  }

  const attendanceMetrics = attendanceMetricsData?.attendanceMetrics;
  const totalHostedParticipants = totalHostedParticipantsData?.totalHostedParticipants ?? 0;

  return (
    <Card>
      <CardHeader title="User Engagement" />

      <Divider />

      <CardContent>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell variant="head">Total Events</TableCell>
              <TableCell>{attendanceMetrics?.totalEvents}</TableCell>
              <TableCell variant="head">Total time</TableCell>
              <TableCell>{getHoursAndMinutes(attendanceMetrics?.totalDuration)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Events hosted</TableCell>
              <TableCell>{attendanceMetrics?.hostedEvents}</TableCell>
              <TableCell variant="head">Hosted time</TableCell>
              <TableCell>{getHoursAndMinutes(attendanceMetrics?.hostedDuration)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Total Attended</TableCell>
              <TableCell>{attendanceMetrics?.attendedEvents}</TableCell>
              <TableCell variant="head">Attended time</TableCell>
              <TableCell>{getHoursAndMinutes(attendanceMetrics?.attendedDuration)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Scheduled attended events</TableCell>
              <TableCell>{attendanceMetrics?.totalAttendedScheduledEvents}</TableCell>
              <TableCell variant="head">Hosted participants</TableCell>
              <TableCell>{totalHostedParticipants}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Nonscheduled attended events</TableCell>
              <TableCell>{attendanceMetrics?.totalAttendedNonScheduledEvents}</TableCell>
              <TableCell variant="head">Scheduled events RSVPs</TableCell>
              <TableCell>{user.engagementMetrics?.totalRsvps}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Articles published</TableCell>
              <TableCell>{totalArticles}</TableCell>
              <TableCell variant="head">Attendance ratio</TableCell>
              <TableCell>
                {Math.round((attendanceRatioData?.attendanceRatio ?? 0) * 100)}%
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default UserPageDetailsMetricsCard;
