import { CircularProgress } from "@mui/material";
import React, { lazy, Suspense } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ArticleLayout from "src/layouts/ArticleLayout";
import EventLayout from "src/layouts/EventLayout";
import LessonLayout from "src/layouts/LessonLayout";
import LiveTablesLayout from "src/layouts/LiveTablesLayout.tsx";
import ReportsLayout from "src/layouts/ReportsLayout";
import ThemesLayout from "src/layouts/ThemesLayout";
import AccountSettingsNewDevicePage from "src/pages/AccountSettingsNewDevicePage";
import AccountSettingsPage from "src/pages/AccountSettingsPage";
import ArticlesDraftsPage from "src/pages/ArticlesDraftsPage";
import CurrentTopicQuestionsPage from "src/pages/CurrentTopicsQuestionsPage.tsx";
import EventCommentsPage from "src/pages/EventCommentsPage";
import EventDetailsPage from "src/pages/EventDetailsPage";
import EventEditPage from "src/pages/EventEditPage";
import EventParticipantsPage from "src/pages/EventParticipantsPage";
import LessonEditPage from "src/pages/LessonEditPage";
import LogoutPage from "src/pages/LogoutPage";
import PopUpNotificationPage from "src/pages/PopUpNotificationPage.tsx";
import PopUpNotificationsPage from "src/pages/PopUpNotificationsPage.tsx";
import ReportsUsersPage from "src/pages/ReportsUsersPage";
import ThemesPage from "src/pages/ThemesPage";
import ArticlesEditPage from "../pages/ArticlesEditPage";
import ArticlesPublishedPage from "../pages/ArticlesPublishedPage";
import BannerPage from "../pages/BannerPage";
import CoversEditPage from "../pages/CoversEditPage";
import CoversPage from "../pages/CoversPage";
import EventsPage from "../pages/EventsPage";
import HomePage from "../pages/HomePage";
import IllustrationsEditPage from "../pages/IllustrationsEditPage";
import IllustrationsPage from "../pages/IllustrationsPage";
import LoginWithSecondFactorPage from "../pages/LoginWithSecondFactorPage";
import MaintenancePage from "../pages/MaintenancePage";
import SlidePage from "../pages/SlidePage";
import SlidersPage from "../pages/SlidersPage";
import ThemeEditPage from "../pages/ThemeEditPage";
import TopicEditPage from "../pages/TopicEditPage";
import TopicsPage from "../pages/TopicsPage";
import UserEditPage from "../pages/UserEditPage";
import UserPage from "../pages/UserPage";
import UsersPage from "../pages/UsersPage";
import VideoSetPage from "../pages/VideoSetPage";
import VideosPage from "../pages/VideosPage";
import ZineCoversEditPage from "../pages/ZineCoversEditPage";
import ZineCoversPage from "../pages/ZineCoversPage";

/**
 * Lazy load routes
 */
const LoginPage = lazy(() => import("../pages/LoginPage"));
const ProtectedLayout = lazy(() => import("../layouts/ProtectedLayout"));

const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/" element={<Navigate to="/login" />} /> */}
        <Route
          path="/login"
          element={
            <Suspense fallback={<CircularProgress />}>
              <LoginPage />
            </Suspense>
          }
        />
        <Route path="/login-otp" element={<LoginWithSecondFactorPage />} />
        <Route path="logout" element={<LogoutPage />} />
        <Route
          path="/"
          element={
            <Suspense fallback={<CircularProgress />}>
              <ProtectedLayout />
            </Suspense>
          }
        >
          <Route path="/account" element={<AccountSettingsPage />} />
          <Route path="/account/new-device" element={<AccountSettingsNewDevicePage />} />
          <Route path="users" element={<UsersPage />} />
          <Route path="users/:userId/edit" element={<UserEditPage />} />
          <Route path="users/:userId" element={<UserPage />} />
          <Route path="events" element={<EventsPage />} />
          <Route path="event/:eventId/*" element={<EventLayout />}>
            <Route path="details" element={<EventDetailsPage />} />
            <Route path="participants" element={<EventParticipantsPage />} />
            <Route path="comments" element={<EventCommentsPage />} />
            <Route path="edit" element={<EventEditPage />} />
          </Route>
          <Route path="covers" element={<CoversPage />} />
          <Route path="covers/:coverId/edit" element={<CoversEditPage />} />
          <Route path="illustrations" element={<IllustrationsPage />} />
          <Route path="illustrations/:illustrationId/edit" element={<IllustrationsEditPage />} />
          <Route path="theme/:themeId/edit" element={<ThemeEditPage />} />
          <Route path="topics" element={<TopicsPage />} />
          <Route path="topic/:topicId/edit" element={<TopicEditPage />} />
          <Route path="lesson/:lessonId" element={<LessonLayout />}>
            <Route path="edit" element={<LessonEditPage />} />
          </Route>
          <Route path="reports" element={<ReportsLayout />}>
            <Route path="users" element={<ReportsUsersPage />} />
            <Route index element={<Navigate to="users" replace />} />
          </Route>
          <Route path="themes" element={<ThemesLayout />}>
            <Route path="standard" element={<ThemesPage isStandard />} />
            <Route path="topic-library" element={<ThemesPage isTopicLibrary />} />
            <Route path="grammar-library" element={<ThemesPage isGrammarLibrary />} />
            <Route path="resource" element={<ThemesPage isResource />} />
            <Route
              path="draft"
              element={
                <ThemesPage
                  isStandard={false}
                  isTopicLibrary={false}
                  isResource={false}
                  isGrammarLibrary={false}
                />
              }
            />
            <Route index element={<Navigate to="standard" replace />} />
          </Route>
          <Route path="live-tables" element={<LiveTablesLayout />}>
            <Route path="current-topic" element={<CurrentTopicQuestionsPage />} />
            <Route path="ice-breakers" element={null} />
          </Route>
          <Route path="zine-covers" element={<ZineCoversPage />} />
          <Route path="zine-covers/:zineCoverId/edit" element={<ZineCoversEditPage />} />
          <Route path="articles" element={<ArticleLayout />}>
            <Route path="published" element={<ArticlesPublishedPage />} />
            <Route path="drafts" element={<ArticlesDraftsPage />} />
            <Route path="edit/:articleId" element={<ArticlesEditPage />} />
            <Route index element={<Navigate to="published" />} />
          </Route>
          <Route path="home-page" element={<HomePage />} />
          <Route path="sliders" element={<SlidersPage />} />
          <Route path="sliders/:sliderId/edit" element={<BannerPage />} />
          <Route path="slide/:slideNo/:slideId/edit" element={<SlidePage />} />
          <Route path="videos" element={<VideosPage />} />
          <Route path="videos/:videoSetId/edit" element={<VideoSetPage />} />
          <Route path="popup-notifications" element={<PopUpNotificationsPage />} />
          <Route path="popup-notifications/:id/edit" element={<PopUpNotificationPage />} />
          <Route path="maintenance" element={<MaintenancePage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
