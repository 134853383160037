import { useSnackbar } from "notistack";
import React, { useState } from "react";

import {
  Box,
  Card,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import Question from "src/components/LiveTableQuestions/Question.tsx";
import { Spacing } from "../types/enum";
import { QuestionModel, useGetCurrentTopicQuestionsQuery } from "../types/graphql";

const CurrentTopicQuestionsPage: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [openForm, setOpenForm] = useState<string | undefined>(undefined);
  const { data, loading, error } = useGetCurrentTopicQuestionsQuery({
    fetchPolicy: "cache-and-network",
  });

  const questions = data?.getCurrentTopicQuestions;
  const topicTitle = questions?.[0]?.topic?.title;

  if (error) {
    enqueueSnackbar(error.message, { variant: "error" });
  }

  return (
    <>
      <Box mb={Spacing.l}>
        <Typography variant="h4" color="textPrimary">
          {topicTitle}
        </Typography>
      </Box>
      <Card>
        {loading ? (
          <Box display="flex" justifyContent="center" p={Spacing.xxl}>
            <CircularProgress />
          </Box>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "70%", minWidth: "200px" }}>
                  <Typography variant="body2" color="secondary">
                    TABLE TOPIC
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" color="secondary">
                    LAST SAVED
                  </Typography>
                </TableCell>
                <TableCell align="right">Edit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {questions?.map((question: QuestionModel) => (
                <Question
                  question={question}
                  key={question.id}
                  openForm={openForm}
                  setOpenForm={setOpenForm}
                />
              ))}
            </TableBody>
          </Table>
        )}
      </Card>
    </>
  );
};

export default CurrentTopicQuestionsPage;
