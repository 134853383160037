import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, FormControl, IconButton, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { isNil, not } from "ramda";
import React from "react";
import { X as CloseIcon } from "react-feather";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { type InferType } from "yup";

import {
  GetCurrentTopicQuestionsDocument,
  QuestionModel,
  useAdminEditQuestionMutation,
} from "src/types/graphql.ts";

import { useErrorHandler } from "src/hooks/errorHandler.ts";
import { Spacing } from "src/types/enum.ts";

/**
 * Types
 */
interface Props {
  question: QuestionModel;
  close: () => void;
}

/**
 * Schema
 */
const schema = yup.object().shape({
  question: yup
    .string()
    .required("This field is required")
    .max(60, "Character limit of 60 exceeded"),
});
type FormValues = InferType<typeof schema>;

const EditQuestionForm: React.FC<Props> = ({ question, close }: Props) => {
  const [editQuestion, { loading }] = useAdminEditQuestionMutation({
    refetchQueries: [GetCurrentTopicQuestionsDocument],
  });
  const { handleError } = useErrorHandler();

  const defaultValues = {
    question: question.question ?? "",
  };

  const { register, formState, handleSubmit } = useForm<FormValues>({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async (form: any): Promise<void> => {
    const variables = {
      input: {
        id: question.id,
        question: form?.question,
      },
    };

    await handleError(editQuestion, {
      onSuccess: () => {
        enqueueSnackbar("Question successfully updated", { variant: "success" });
      },
      finally: close,
    })({
      variables,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box display="flex" alignItems="center">
        <FormControl fullWidth>
          <TextField
            placeholder="Add the question here (max 60 characters)"
            variant="outlined"
            {...register("question")}
            error={not(isNil(formState?.errors?.question))}
            helperText={
              not(isNil(formState?.errors?.question))
                ? formState?.errors?.question?.message
                : undefined
            }
          />
        </FormControl>
        <Box mx={Spacing.s}>
          <Button color="primary" type="submit" disabled={loading || !formState.isValid}>
            Save
          </Button>
        </Box>
        <IconButton size="large" onClick={close}>
          <CloseIcon />
        </IconButton>
      </Box>
    </form>
  );
};

export default EditQuestionForm;
