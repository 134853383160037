import { Box, type Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { Spacing } from "../types/enum.ts";

/**
 * Types
 */
interface Props {
  subtitle: string;
  messages: string[];
}

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) => ({
  errorText: {
    color: "#fdd835",
  },
  errorsContainer: {
    maxHeight: "300px",
    overflowY: "auto",
    border: "1px solid grey",
    borderRadius: "4px",
    padding: theme.spacing(Spacing.sm),
  },
}));

const EmailValidationWarningMessages: React.FC<Props> = ({ subtitle, messages }: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.errorText}>
      <Typography variant="body2" mb={Spacing.s}>
        {subtitle}
      </Typography>
      <Box className={classes.errorsContainer}>
        {messages.map((message) => (
          <Typography key={message} variant="body2">
            {message}
          </Typography>
        ))}
      </Box>
    </Box>
  );
};

export default EmailValidationWarningMessages;
