import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { isEmpty, isNil, not } from "ramda";
import React, { useMemo, useState } from "react";
import { Controller, type SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { Spacing } from "../../types/enum";

import { parseEmails } from "src/components/BulkInvite/helpers.ts";
import EmailValidationWarningMessages from "src/components/EmailValidationWarningMessages.tsx";
import { useOnOffSwitch } from "src/helpers/hooks.tsx";
import { useSendCustomNotificationsMutation } from "src/types/graphql.ts";

/**
 * Types
 */
interface Props {
  notificationId: string;
}

/**
 * Schema
 */
const schema = yup.object({
  emailsStr: yup.string().trim().required("This field is required"),
});
type FormValues = yup.InferType<typeof schema>;

const NotificationEmailsForm: React.FC<Props> = ({ notificationId }) => {
  const [isOpen, open, close] = useOnOffSwitch();
  const [serverMessages, setServerMessages] = useState<string[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  const [sendSystemNotifications] = useSendCustomNotificationsMutation();

  const defaultValues = {
    emailsStr: "",
  };

  const {
    control,
    formState,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const emailsStr = watch("emailsStr");

  const emailsList = useMemo(() => {
    return parseEmails(emailsStr);
  }, [emailsStr]);

  const onSubmit: SubmitHandler<FormValues> = async (): Promise<void> => {
    try {
      const variables = {
        id: notificationId,
        emails: emailsList,
      };
      const response = await sendSystemNotifications({ variables });
      const warningMessages = response.data?.sendCustomNotifications?.warningMessages;
      const sentTo = response.data?.sendCustomNotifications?.sentTo;
      if (sentTo && sentTo > 0) {
        enqueueSnackbar(`Notification sent to ${sentTo} users.`, { variant: "success" });
      }
      if (warningMessages && !isEmpty(warningMessages)) {
        setServerMessages(warningMessages);
      }
      reset();
      close();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const onClear = () => {
    reset();
    setServerMessages([]);
  };

  return (
    <Card>
      <Box p={Spacing.l}>
        <form>
          <Typography variant="h5" color="textPrimary">
            Copy and paste comma separated emails into the field bellow
          </Typography>

          <Box my={Spacing.s}>
            <Controller
              name="emailsStr"
              control={control}
              render={({ field }) => (
                <TextField
                  InputProps={field}
                  fullWidth
                  multiline
                  rows={6}
                  placeholder="Paste here"
                  sx={{ marginBottom: Spacing.sm }}
                  helperText={errors.emailsStr?.message}
                  error={!isNil(errors.emailsStr?.message)}
                />
              )}
            />
            <Box display="flex" justifyContent="flex-end">
              <Typography variant="body2" color="textSecondary">
                {emailsList.length} recipients
              </Typography>
            </Box>
          </Box>

          {not(isEmpty(serverMessages)) ? (
            <Box mb={Spacing.m}>
              <EmailValidationWarningMessages
                subtitle="Invalid recipients"
                messages={serverMessages}
              />
            </Box>
          ) : null}

          <Box mb={Spacing.sm}>
            <Button variant="contained" fullWidth onClick={open}>
              Send
            </Button>
          </Box>
          <Button variant="outlined" fullWidth onClick={onClear}>
            Clear
          </Button>

          <Dialog maxWidth="lg" open={isOpen} onClose={close}>
            <DialogContent>
              <Box mb={Spacing.sm} display="flex" flexDirection="column" alignItems="center">
                <Typography variant="body1" color="textPrimary">
                  Are you sure?
                </Typography>
                <Box mt={Spacing.sm} />
                <Typography variant="body2" color="textSecondary">
                  You are about to send a pop-up notifications to {emailsList.length} users
                </Typography>
              </Box>
            </DialogContent>

            <DialogActions>
              <Box width="100%" display="flex" justifyContent="space-around" alignItems="center">
                <Button onClick={close}>Cancel</Button>
                <Button
                  type="submit"
                  disabled={not(formState.isValid) || formState.isSubmitting}
                  onClick={handleSubmit(onSubmit)}
                >
                  Send
                </Button>
              </Box>
            </DialogActions>
          </Dialog>
        </form>
      </Box>
    </Card>
  );
};

export default NotificationEmailsForm;
