import { CircularProgress } from "@mui/material";
import React from "react";
import { useParams } from "react-router";
import EditPopUpNotificationFormCont from "src/components/Notifications/EditPopUpNotificationFormCont.tsx";
import NotificationEmailsForm from "src/components/Notifications/NotificationsEmailsForm.tsx";
import {
  CustomNotificationDisplayModel,
  useGetCustomNotificationDisplayQuery,
} from "src/types/graphql.ts";

const PopUpNotificationPage: React.FC = () => {
  const params = useParams();

  const { data, loading } = useGetCustomNotificationDisplayQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      id: params?.id ?? "",
    },
  });

  if (loading) {
    return <CircularProgress />;
  }

  if (!data?.getCustomNotificationDisplay) {
    return null;
  }

  return (
    <>
      <EditPopUpNotificationFormCont
        notificationDisplay={data?.getCustomNotificationDisplay as CustomNotificationDisplayModel}
      />
      <NotificationEmailsForm notificationId={data?.getCustomNotificationDisplay.id} />
    </>
  );
};

export default PopUpNotificationPage;
