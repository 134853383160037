import { IconButton, SvgIcon, TableCell, TableRow } from "@mui/material";
import { format } from "date-fns";
import React from "react";
import { Edit as EditIcon } from "react-feather";
import EditQuestionForm from "src/components/LiveTableQuestions/EditQuestionForm.tsx";
import { QuestionModel } from "src/types/graphql.ts";

/**
 * Types
 */
interface Props {
  question: QuestionModel;
  openForm: string | undefined;
  setOpenForm: (value: string | undefined) => void;
}

const Question: React.FC<Props> = ({ question, openForm, setOpenForm }: Props) => {
  const isOpen = question.id === openForm;

  return (
    <TableRow>
      <TableCell sx={{ width: "70%", minWidth: "200px" }}>
        {isOpen ? (
          <EditQuestionForm question={question} close={() => setOpenForm(undefined)} />
        ) : (
          question.question
        )}
      </TableCell>

      <TableCell>{format(new Date(question?.updatedAt), "yyyy-MM-dd")}</TableCell>

      <TableCell align="right">
        <IconButton
          size="large"
          onClick={() => (isOpen ? setOpenForm(undefined) : setOpenForm(question.id))}
        >
          <SvgIcon fontSize="small">
            <EditIcon />
          </SvgIcon>
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default Question;
