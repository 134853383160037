import { ApolloError } from "@apollo/client";
import LoadingButton from "@mui/lab/LoadingButton";
import { Button, DialogActions, DialogContent, TextField, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { isEmpty, isNil } from "ramda";
import React from "react";
import { Controller, type SubmitHandler, useForm } from "react-hook-form";
import { Spacing } from "src/types/enum";
import { useBulkInviteWithUserIdsMutation } from "src/types/graphql";
import EmailValidationWarningMessages from "../../EmailValidationWarningMessages.tsx";
import BulkInviteConfirmationMessage from "../BulkInviteConfirmationMessage";
import BulkInviteParticipantsNumber from "../BulkInviteParticipantsNumber";
import BulkInviteSelectionFormHeader from "./BulkInviteSelectionFormHeader";

/**
 * Types
 */
interface Props {
  onClose: () => void;
  validatedUserIds: string[];
  eventId: string;
  eventTitle: string | null;
  serverMessages: string[];
  setServerMessages: React.Dispatch<React.SetStateAction<string[]>>;
}

interface FormValues {
  eventId: string;
  userIds: string[];
}

const BulkInviteSelectionInviteForm: React.FC<Props> = ({
  onClose,
  eventId,
  eventTitle,
  validatedUserIds,
  serverMessages,
  setServerMessages,
}: Props) => {
  const defaultValues = {
    eventId,
    userIds: validatedUserIds,
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting, isSubmitted },
  } = useForm<FormValues>({
    defaultValues,
  });
  const [bulkInviteWithUserIds] = useBulkInviteWithUserIdsMutation();

  const onSubmit: SubmitHandler<FormValues> = async (formData) => {
    try {
      const { data } = await bulkInviteWithUserIds({
        variables: {
          eventId: formData.eventId,
          input: {
            userIds: formData.userIds,
          },
        },
      });

      const messages = data?.bulkInviteWithUserIds?.warningMessages;
      if (messages && !isEmpty(messages)) {
        setServerMessages(messages);
      } else {
        onCancel();
      }
      enqueueSnackbar(
        `Success! ${validatedUserIds.length} participants have been invited to this event.`,
        {
          variant: "success",
        },
      );
    } catch (error) {
      console.error(error);
      if (error instanceof ApolloError) {
        enqueueSnackbar(error.message, { variant: "error" });
      } else {
        console.error(error);
      }
    }
  };

  const onCancel = () => {
    reset();
    onClose();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent>
        <BulkInviteSelectionFormHeader />

        <Controller
          name="eventId"
          control={control}
          render={({ field }) => (
            <TextField
              InputProps={field}
              label="Event id"
              fullWidth
              placeholder="Paste event id here"
              sx={{ marginBottom: Spacing.sm }}
              helperText={errors.eventId?.message}
              error={!isNil(errors.eventId?.message)}
              disabled
            />
          )}
        />

        <BulkInviteParticipantsNumber number={validatedUserIds.length} />

        {eventTitle && (
          <Typography variant="body2" color="textPrimary" mb={Spacing.sm}>
            Event:
            <Typography component="span" fontWeight="bold">
              {eventTitle}
            </Typography>
          </Typography>
        )}

        <BulkInviteConfirmationMessage number={validatedUserIds.length} />

        {!isEmpty(serverMessages) && isSubmitted && (
          <EmailValidationWarningMessages
            subtitle="Some users were not invited."
            messages={serverMessages}
          />
        )}
      </DialogContent>

      <DialogActions>
        {isSubmitted ? (
          <Button onClick={onCancel}>Close</Button>
        ) : (
          <Button onClick={onCancel}>Cancel</Button>
        )}

        {!isSubmitted && (
          <LoadingButton type="submit" loading={isSubmitting} disabled={isSubmitting}>
            Invite
          </LoadingButton>
        )}
      </DialogActions>
    </form>
  );
};

export default BulkInviteSelectionInviteForm;
