import { Box, Divider, Tab, Tabs, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";
import { Outlet, useMatch } from "react-router-dom";

import { Spacing } from "../types/enum";

const LiveTablesLayout: React.FC = () => {
  const navigate = useNavigate();
  const match = useMatch("/live-tables/:pageName");
  const pageName = match?.params.pageName ?? "current-topic";

  const onChange = (_: React.ChangeEvent<unknown>, nextPageName: string) => {
    navigate(`/live-tables/${nextPageName}`);
  };

  return (
    <>
      <Box mb={Spacing.m}>
        <Typography variant="h3" color="textPrimary">
          Live Tables
        </Typography>
      </Box>

      <Box>
        <Tabs
          scrollButtons="auto"
          variant="scrollable"
          textColor="secondary"
          value={pageName}
          onChange={onChange}
        >
          <Tab label="Current Topic" value="current-topic" />
          <Tab label="Ice Breakers" value="ice-breakers" />
        </Tabs>
      </Box>

      <Divider />

      <Box mt={Spacing.m}>
        <Outlet />
      </Box>
    </>
  );
};

export default LiveTablesLayout;
