import { ApolloError } from "@apollo/client";
import { useSnackbar } from "notistack";
import { isEmpty } from "ramda";
import React from "react";
import { Controller, type SubmitHandler, useForm } from "react-hook-form";

import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button, DialogActions, DialogContent, TextField } from "@mui/material";

import { GET_EVENT_PARTICIPANTS } from "../../gql/queries/event";
import { Spacing } from "../../types/enum";
import { useBulkInviteWithUserIdsMutation } from "../../types/graphql";
import EmailValidationWarningMessages from "../EmailValidationWarningMessages.tsx";
import BulkInviteConfirmationMessage from "./BulkInviteConfirmationMessage";
import BulkInviteEmailsFormHeader from "./BulkInviteEmailsFormHeader";

/**
 * Types
 */
interface Props {
  eventId: string;
  emailsStr: string;
  userIds: string[];
  onClose: () => void;
  onGoBack: () => void;
  serverMessages: string[];
  setServerMessages: React.Dispatch<React.SetStateAction<string[]>>;
}

interface FormValues {
  userIds: string[];
}

const BulkInviteEmailsInviteForm: React.FC<Props> = ({
  eventId,
  userIds,
  onClose,
  onGoBack,
  emailsStr,
  serverMessages,
  setServerMessages,
}: Props) => {
  const [bulkInviteWithUserIds] = useBulkInviteWithUserIdsMutation({
    refetchQueries: [{ query: GET_EVENT_PARTICIPANTS, variables: { id: eventId } }],
  });
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = {
    userIds,
    emailsStr,
  };

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    formState: { errors, isSubmitting, isSubmitted },
  } = useForm({
    defaultValues,
  });

  const onSubmit: SubmitHandler<FormValues> = async (formData): Promise<void> => {
    if (!eventId) {
      enqueueSnackbar("Event id is required", { variant: "error" });
      return;
    }

    if (isEmpty(errors)) {
      try {
        const { data } = await bulkInviteWithUserIds({
          variables: { eventId, input: { userIds: formData.userIds } },
        });

        const messages = data?.bulkInviteWithUserIds?.warningMessages;
        if (messages && !isEmpty(messages)) {
          setServerMessages(messages);
        } else {
          onCancel();
        }
        enqueueSnackbar(
          `Success! ${formData.userIds.length} participants have been invited to this event.`,
          {
            variant: "success",
          },
        );
      } catch (error) {
        if (error instanceof ApolloError) {
          enqueueSnackbar(error.message, { variant: "error" });
        } else {
          console.error(error);
        }
      }
    }
  };

  const onCancel = () => {
    reset();
    onClose();
  };

  const usersToInviteNumber = getValues("userIds")?.length || 0;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent>
        <Box mb={Spacing.sm} sx={{ maxWidth: "385px" }}>
          <BulkInviteEmailsFormHeader />

          <Controller
            name="emailsStr"
            control={control}
            render={({ field }) => (
              <TextField
                InputProps={field}
                fullWidth
                multiline
                rows={6}
                placeholder="Paste here"
                disabled
                sx={{ marginBottom: Spacing.sm }}
              />
            )}
          />

          <BulkInviteConfirmationMessage number={usersToInviteNumber} />

          {!isEmpty(serverMessages) && isSubmitted && (
            <EmailValidationWarningMessages
              subtitle="Some users were not invited."
              messages={serverMessages}
            />
          )}
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={onGoBack}>Back</Button>
        {!isSubmitted && (
          <LoadingButton type="submit" loading={isSubmitting} disabled={isSubmitting}>
            <span>Confirm & Invite</span>
          </LoadingButton>
        )}
      </DialogActions>
    </form>
  );
};

export default BulkInviteEmailsInviteForm;
