import { gql } from "@apollo/client";

export const ME = gql`
  query me {
    me {
      id
      name
      email
      avatarUrl
      needSpeakingPartner
      cambridgeEnglishLevel
      kind
      firstName
      otherLanguage
      isComplete
      badge
      createdAt
      lastName
      city
      country
      timezone
      story
      birthday
      interests
      occupation {
        id
        name
      }
      showBirthday
      showBirthdayYear
      nationality
      nativeLanguage
      completedPercentage
      isVisible
      wantsNewsletter
      availability {
        from
        until
      }
      promotionalCredits
    }
  }
`;

export const SEARCH_USERS = gql`
  query adminListUsers($filters: AdminFilterUsersInput!) {
    adminListUsers(filters: $filters) {
      items {
        id
        name
        email
        name
        avatarUrl
        needSpeakingPartner
        cambridgeEnglishLevel
        otherLanguage
        isComplete
        isVerified
        occupation {
          id
          name
        }
        badge
        createdAt
        city
        country
        timezone
        story
        birthday
        interests
        showBirthday
        nativeLanguage
        completedPercentage
        isVisible
        wantsNewsletter
        availability {
          from
          until
        }
      }
      total
    }
  }
`;

export const GET_SELECTED_USERS = gql`
  query adminGetSelectedUsers($filters: AdminFilterUsersInput!) {
    adminGetSelectedUsers(filters: $filters) {
      id
      firstName
      lastName
      email
      cambridgeEnglishLevel
      speakingLevelScore
      speakingLevel
      availableAssessmentCharges
      takenSpeakingAssessment
      city
      country
      birthday
      nativeLanguage
      needSpeakingPartner
      badge
      isVerified
      lastOnlineAt
      availability {
        from
        until
      }
      timezone
      isActive
      isComplete
      occupation {
        id
        name
      }
      interests
      englishSkills
      createdAt
      kind
      customers {
        subscriptions {
          status
          plan
          currentPeriodStart
          currentPeriodEnd
          resumesAt
          cancelledAt
        }
      }
      pauseCredits
      showBirthday
      completedPercentage
      isVisible
      wantsNewsletter
    }
  }
`;

export const GET_PLAN_DETAILS = gql`
  query adminGetPlanDetails($userId: String!) {
    adminGetPlanDetails(userId: $userId) {
      name
      cbId
      subscriptionChargebeeId
      customerChargebeeId
    }
  }
`;

export const GET_USER_ADMIN = gql`
  query getUserAdmin($id: String!) {
    getUserAdmin(id: $id) {
      id
      email
      name
      createdAt
      kind
      avatarUrl
      needSpeakingPartner
      cambridgeEnglishLevel
      speakingLevelScore
      speakingLevel
      firstName
      otherLanguage
      isComplete
      badge
      createdAt
      lastName
      isActive
      isBanned
      isVerified
      hasPassword
      city
      country
      timezone
      story
      birthday
      interests
      occupation {
        id
        name
      }
      badges {
        id
        type
        achievedDate
      }
      isAgeVisible
      showBirthday
      nativeLanguage
      completedPercentage
      isVisible
      isFeatured
      wantsNewsletter
      pauseCredits
      hasActivePlan
      availability {
        from
        until
      }
      notes {
        id
        description
      }
      availableAssessmentCharges
      engagementMetrics {
        totalRsvps
      }
      extraInvitedFriends
    }
  }
`;

export const GET_OCCUPATIONS = gql`
  query adminGetOccupations {
    adminGetOccupations {
      id
      name
    }
  }
`;

export const GET_GROUPED_OCCUPATIONS = gql`
  query adminGetGroupedOccupations {
    adminGetGroupedOccupations {
      id
      name
      industry {
        id
        name
      }
    }
  }
`;

export const GET_INVITED_FRIENDS_STATS = gql`
  query getInvitedFriendsStats($userId: String!) {
    getInvitedFriendsStats(userId: $userId) {
      invitedFriendsTotal
      invitedPaidFriends
    }
  }
`;

export const ASSESSMENT_RESULTS = gql`
  query listPaginatedAssessmentResults($filters: PaginatedAssessmentResultInput!) {
    listPaginatedAssessmentResults(filters: $filters) {
      total
      items {
        id
        assessmentId
        cefrScore
        halloScore
        createdAt
      }
    }
  }
`;

export const GET_USER_ASSESSMENT_RESULTS = gql`
  query getUserAssessmentResults($userId: String!, $filters: PaginatedAssessmentResultInput!) {
    getUserAssessmentResults(userId: $userId, filters: $filters) {
      total
      items {
        id
        assessmentId
        cefrScore
        halloScore
        createdAt
        isAddedByAdmin
      }
    }
  }
`;

export const TOTAL_HOSTED_PARTICIPANTS = gql`
    query totalHostedParticipants($userId: String!) {
        totalHostedParticipants(userId: $userId)
    }
`;

export const TOTAL_PUBLISHED_ARTICLES = gql`
    query totalPublishedArticles($userId: String!) {
        totalPublishedArticles(userId: $userId)
    }
`;

export const ATTENDANCE_RATIO = gql`
    query attendanceRatio($userId: String!) {
        attendanceRatio(userId: $userId)
    }
`;

export const ATTENDANCE_METRICS = gql`
    query attendanceMetrics($userId: String!) {
        attendanceMetrics(userId: $userId) {
          totalEvents
          totalOneToOnes
          totalGroup
          totalWebinars
          totalInPerson
          totalOther
          totalDuration
          hostedEvents
          hostedOneToOnes
          hostedGroup
          hostedWebinars
          hostedInPerson
          hostedOther
          hostedDuration  
          attendedEvents
          totalAttendedScheduledEvents
          totalAttendedNonScheduledEvents
          attendedOneToOnes
          attendedGroup
          attendedWebinars
          attendedInPerson
          attendedOther
          attendedDuration  
          videoCalls  
        }
    }
`;
