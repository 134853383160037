import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Card,
  CircularProgress,
  IconButton,
  Link,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { Edit as EditIcon } from "react-feather";
import { useNavigate } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import { DEFAULT_OFFSET, ROWS_PER_PAGE_DEFAULT, ROWS_PER_PAGE_OPTIONS } from "src/helpers/const.ts";
import { usePagination } from "src/helpers/hooks.tsx";
import {
  AdminListPaginatedCustomNotificationDisplayDocument,
  type CustomNotificationDisplayModel,
  useAdminCreateCustomNotificationDisplayMutation,
  useAdminListPaginatedCustomNotificationDisplayQuery,
} from "src/types/graphql.ts";
import { Spacing } from "../types/enum";

const PopUpNotificationsPage: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [offset, setOffset] = useState<number>(DEFAULT_OFFSET);
  const [limit, setLimit] = useState<number>(ROWS_PER_PAGE_DEFAULT);

  const { data, loading } = useAdminListPaginatedCustomNotificationDisplayQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      filters: {
        limit,
        offset,
      },
    },
  });

  const { page, rowsPerPage, items, onChangePage, onChangeRowsPerPage } = usePagination(
    data?.adminListPaginatedCustomNotificationDisplay?.items ?? [],
  );

  const [create] = useAdminCreateCustomNotificationDisplayMutation({
    refetchQueries: [
      {
        query: AdminListPaginatedCustomNotificationDisplayDocument,
        variables: {
          filters: {
            limit,
            offset,
          },
        },
      },
    ],
  });

  const onCreate = async () => {
    try {
      const response = await create();
      navigate(
        `/popup-notifications/${response.data?.adminCreateCustomNotificationDisplay?.id}/edit`,
      );
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  useEffect(() => {
    setOffset(page * rowsPerPage);
  }, [page, rowsPerPage]);

  useEffect(() => {
    setLimit(rowsPerPage);
  }, [rowsPerPage]);

  return (
    <>
      <Box mb={Spacing.m} display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h3" color="textPrimary">
          Popup Notifications
        </Typography>
        <Button onClick={onCreate}>Create Notification</Button>
      </Box>

      <Card>
        {loading ? (
          <Box display="flex" justifyContent="center" p={Spacing.xxl}>
            <CircularProgress />
          </Box>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell align="right">Edit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items?.map((notification: CustomNotificationDisplayModel) => (
                <TableRow key={notification.id}>
                  <TableCell>{notification.title ?? "Untitled"}</TableCell>

                  <TableCell align="right">
                    <Link
                      component={RouterLink}
                      to={`/popup-notifications/${notification.id}/edit`}
                    >
                      <IconButton size="large">
                        <SvgIcon fontSize="small">
                          <EditIcon />
                        </SvgIcon>
                      </IconButton>
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>

            <TableFooter>
              <TableRow>
                <TablePagination
                  page={page}
                  count={data?.adminListPaginatedCustomNotificationDisplay.total ?? 0}
                  onPageChange={onChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={onChangeRowsPerPage}
                  rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                />
              </TableRow>
            </TableFooter>
          </Table>
        )}
      </Card>
    </>
  );
};

export default PopUpNotificationsPage;
