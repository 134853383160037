import { ApolloError } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import { isEmpty, isNil } from "ramda";
import React from "react";
import { Controller, type SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";

import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button, DialogActions, DialogContent, TextField } from "@mui/material";

import { Spacing } from "../../types/enum";
import { useValidateUsersForEmailBulkInviteMutation } from "../../types/graphql";
import EmailValidationWarningMessages from "../EmailValidationWarningMessages.tsx";
import BulkInviteEmailsFormHeader from "./BulkInviteEmailsFormHeader";
import BulkInviteParticipantsNumber from "./BulkInviteParticipantsNumber";
import { parseEmails } from "./helpers";
import { FormStates } from "./types";

/**
 * Types
 */
interface Props {
  eventId: string;
  emailsStr: string;
  userIds: string[];
  onClose: () => void;
  onValidated: ({ emails, userIds }: { emails: string; userIds: string[] }) => void;
  formState: FormStates;
  setFormState: React.Dispatch<React.SetStateAction<FormStates>>;
  serverMessages: string[];
  setServerMessages: React.Dispatch<React.SetStateAction<string[]>>;
}

/**
 * Schema
 */
const schema = yup.object({
  emailsStr: yup.string().trim().required("This field is required"),
});
type FormValues = yup.InferType<typeof schema>;

const BulkInviteEmailsValidateForm: React.FC<Props> = ({
  eventId,
  emailsStr,
  userIds,
  onClose,
  onValidated,
  formState,
  setFormState,
  serverMessages,
  setServerMessages,
}: Props) => {
  const [validateUsersForEmailBulkInvite] = useValidateUsersForEmailBulkInviteMutation();
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = {
    emailsStr: emailsStr || "",
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onSubmit: SubmitHandler<FormValues> = async (formData): Promise<void> => {
    if (!eventId) {
      enqueueSnackbar("Event id is required", { variant: "error" });
      return;
    }

    try {
      const emailsList = parseEmails(formData.emailsStr);
      const { data } = await validateUsersForEmailBulkInvite({
        variables: { eventId, input: { emails: emailsList } },
      });
      const userIds = data?.validateUsersForEmailBulkInvite?.userIds;

      if (isNil(userIds)) {
        enqueueSnackbar("Unexpected emails validation error.", { variant: "error" });
        return;
      }
      const warningMessages = data?.validateUsersForEmailBulkInvite?.warningMessages;
      if (warningMessages && !isEmpty(warningMessages)) {
        setServerMessages(warningMessages);
      }

      if (!isEmpty(userIds)) {
        onValidated({ emails: formData.emailsStr, userIds });
        setFormState(isEmpty(warningMessages) ? FormStates.Confirm : FormStates.PreConfirm);
      }
    } catch (error) {
      if (error instanceof ApolloError) {
        enqueueSnackbar(error.message, { variant: "error" });
      } else {
        console.error(error);
      }
    }
  };

  const onCancel = () => {
    reset();
    onClose();
  };

  const onContinue = () => {
    setServerMessages([]);
    setFormState(FormStates.Confirm);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent>
        <Box mb={Spacing.sm} sx={{ maxWidth: "385px" }}>
          <BulkInviteEmailsFormHeader />

          <Controller
            name="emailsStr"
            control={control}
            render={({ field }) => (
              <TextField
                InputProps={field}
                fullWidth
                multiline
                rows={6}
                placeholder="Paste here"
                sx={{ marginBottom: Spacing.sm }}
                helperText={errors.emailsStr?.message}
                error={!isNil(errors.emailsStr?.message)}
              />
            )}
          />

          <BulkInviteParticipantsNumber number={userIds.length} />

          {!isEmpty(serverMessages) && !isEmpty(userIds) && (
            <EmailValidationWarningMessages
              subtitle="Some users cannot be invited. You can invite validated users or update the emails and validate again."
              messages={serverMessages}
            />
          )}
          {!isEmpty(serverMessages) && isEmpty(userIds) && (
            <EmailValidationWarningMessages
              subtitle="No users can be invited, update the emails."
              messages={serverMessages}
            />
          )}
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <LoadingButton type="submit" loading={isSubmitting} disabled={isSubmitting}>
          Validate
        </LoadingButton>
        {formState === FormStates.PreConfirm && <Button onClick={onContinue}>Continue</Button>}
      </DialogActions>
    </form>
  );
};

export default BulkInviteEmailsValidateForm;
